<template>
    <div class="coupon-index">
        <h1 class="coupon-index__title">Últimos cupones de descuento publicados</h1>
        <section class="coupon-index__section">
            <div class="flex flex-col">
                <div
                    v-for="(item, key) in pageData.page.data.coupons.data"
                    :key="key"
                    :style="{ order: key + 1 }"
                    class="mb-3"
                >
                    <DelayHydration>
                        <DCardAdmin v-if="item.is_admin" :info="item" />
                        <DCardMain v-else :info="item" in-stores-route="true" />
                    </DelayHydration>
                </div>
            </div>
        </section>
    </div>
</template>
<script lang="ts" setup>
import { replacePlaceholders } from '~/util/textFunction'
import type { Api, ServicesQueryParameters } from '~~/global'
import { useDateFunctions } from '~/composables/DateFunctions'

const {
    public: {
        variant: {
            metaData: { siteOrigin, siteName },
        },
    },
} = useRuntimeConfig()

const { currentDate } = useDateFunctions()

const currentDates = currentDate()
const Route = useRoute()

const { buildHeaders, baseUrl, endpoints } = useApiClient()

const headers = buildHeaders()

const { data: couponsData, error } = await useAsyncData('coupons-index-page', async () => {
    try {
        const pageData = await $fetch<Api.Responses.Pages.Coupons>(endpoints.pages.coupons.path, {
            method: 'GET',
            headers,
            baseURL: baseUrl,
            params: {
                per_page: 30,
            } as ServicesQueryParameters.GetDiscounts,
        })

        return {
            pageData,
        }
    } catch (error) {
        throw createError({
            statusCode: 500,
            message: 'Algo salió mal',
        })
    }
})

if (!couponsData.value) throw new Error('Coupons data not found')

const { pageData } = couponsData.value

const [title, description] = replacePlaceholders(
    [
        [':month', currentDates.month || ''],
        [':day', String(currentDates.day) || ''],
        [':year', String(currentDates.year) || ''],
        [':site', siteName || ''],
    ],
    [pageData.page.title || '', pageData.page.description || ''],
)

useSeoMeta({
    title: title,
    ogUrl: siteOrigin + Route.path,
    ogTitle: title,
    description: description,
    ogDescription: description,
    ogImageAlt: title,
    twitterCard: 'summary_large_image',
    ...(Route.path !== '/' ? { robots: 'noindex' } : {}),
})

useHead({
    link: [
        {
            rel: 'canonical',
            href: siteOrigin + Route.path,
        },
    ],
})
</script>

<style lang="postcss" scoped>
.coupon-index {
    @apply container;
    &__title {
        @apply my-3 text-center;
    }
    &__section {
        @apply mx-auto mb-5 max-w-5xl;
    }
}
</style>
